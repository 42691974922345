.jumbotron {
  /* background-color: #b7dff9; */
}

.projects {
}

.about-me {
  background-color: white;
}

/* .contact {
 background-color: #b7dff9;
}  */

.instagram-icons,
.github-icons,
.linkedin-icons,
.whatsapp-icons,
.location-icons,
.phone-icons,
.mail-icons {
  color: ;
}

/* .copyright {
  background-color: #b7dff9;
} */

.copyright-container {
  border-top: 1px solid white;
}

/* @keyframes move {
  0% {
    transform: scale(1) translate(10px, -30px);
  }
  38% {
    transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
  }
  40% {
    transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg);
  }
  78% {
    transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
  }
  80% {
    transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg);
  }
  100% {
    transform: scale(1) translate(10px, -30px);
  }
} */
